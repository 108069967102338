<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { computed } from 'vue'
  import { useLocaleStore } from '~/store/Locale'
  import { useGameContentStore } from '~/store/GameContent'
  import { ref, watch } from '#imports'
  import { GameLocationId } from '~/model/GameLocation/GameLocation'
  import useGameInteraction from '~/composables/GameInteraction/GameInteraction'
  import {
    GameInteractionCategory,
    GameInteractionId,
    GameInteractionListContent,
  } from '~/model/GameInteraction/GameInteraction'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import useDebug from '~/composables/Debug/Debug'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'
  import { IconBoxedFacet } from '~/components/IconBoxed/IconBoxedFacet'
  import useTranslation from '~/composables/Translation/Translation'
  import { useGameStateStore } from '~/store/GameState'
  import { UiIconId, UiIconSizeId } from '~/model/UiIcon/UiIcon'

  interface Props extends UseBemProps {
    facets?: Array<string>
    locationId: GameLocationId
  }

  const localeStore = useLocaleStore()
  const gameContentStore = useGameContentStore()
  const gameStateStore = useGameStateStore()

  const props = defineProps<Props>()
  const { t } = useTranslation()
  const { isDebug } = useDebug()
  const { bemAdd, bemFacets } = useBem('c-list-interaction', props, {})
  const { getList, toGameInteraction } = useGameInteraction()

  const { assignPendingData } = gameContentStore
  const { completedInteractionList } = storeToRefs(gameStateStore)
  const { localeId } = storeToRefs(localeStore)
  const interactionList = ref<Array<GameInteractionListContent> | null>(null)

  const rootClasses = computed(() => {
    return [bemFacets.value, bemAdd(interactionList.value?.length ? 'has-entries' : '')]
  })

  const getFirstLetter = (category: GameInteractionCategory) => {
    return t(`interaction-category.${category}`).charAt(0)
  }

  const getCompleted = (id: GameInteractionId): boolean => {
    return completedInteractionList.value.has(id)
  }

  const getEntryClasses = (id: GameInteractionId): Array<string> => {
    const isCompleted = getCompleted(id)
    return [bemAdd(isCompleted ? 'is-completed' : '', 'entry')]
  }

  watch(
    () => props.locationId,
    async (newVal) => {
      if (!newVal) {
        return
      }

      const req = await getList(localeId.value, props.locationId)
      assignPendingData<Array<GameInteractionListContent>>(req, (data) => {
        interactionList.value = data.value
      })
    },
    { immediate: true },
  )
</script>

<template>
  <div :class="rootClasses" class="c-list-interaction">
    <ul class="u-reset c-list-interaction__list">
      <li
        v-for="{ id, name, category } in interactionList"
        :key="id"
        class="c-list-interaction__entry"
        :class="getEntryClasses(id)"
      >
        <div class="c-list-interaction__data">
          <div class="c-list-interaction__image-wrap">
            <IconBoxed
              :facets="[IconBoxedFacet.Small, getCompleted(id) ? IconBoxedFacet.Disabled : '']"
              :visual-theme="VisualThemeId.Negative"
            >
              <template #letter>
                <template v-if="getCompleted(id)">
                  <UiIcon
                    :id="UiIconId.IconControlMediumCheckmark"
                    class="c-list-interaction__letter-icon"
                    :colorize="true"
                    :size="UiIconSizeId.Medium"
                  />
                </template>
                <template v-else>
                  {{ getFirstLetter(category) }}
                </template>
              </template>
            </IconBoxed>
          </div>
          <span class="c-list-interaction__title">
            {{ t(`interaction-category.${category}`) }}: {{ name }}
            <code v-if="isDebug" class="u-debug">
              <NuxtLink :to="toGameInteraction(id)"> link</NuxtLink>
            </code>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/color/color' as col;

  .c-list-interaction {
    &.c-list-interaction--has-entries {
      margin-top: 12px;
    }

    &.c-list-interaction--within-list-location {
      @include type.copy-small;
      padding-left: 40px;

      .c-list-interaction__entry.c-list-interaction__entry--is-completed {
        color: col.$monochrome-quicksilver;
      }
    }
  }

  .c-list-interaction__list {
    display: grid;
    flex-flow: column nowrap;
    row-gap: 8px;
  }

  .c-list-interaction__entry {
    display: flex;
    flex-flow: column nowrap;
  }

  .c-list-interaction__data {
    display: flex;
    align-items: center;
  }

  .c-list-interaction__image-wrap {
    align-self: flex-start;
    flex: 0 0 auto;
    margin-top: 2px;
  }

  .c-list-interaction__image {
    width: 100%;
  }

  .c-list-interaction__title {
    margin-left: 8px;
  }

  .c-list-interaction__letter-icon {
    padding: 1px;
  }
</style>
